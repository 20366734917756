import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-43188d62"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "description" }
const _hoisted_2 = { class: "left" }
const _hoisted_3 = { class: "right" }
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.text, (paragraph, i) => {
        return (_openBlock(), _createElementBlock("p", {
          class: "paragraph",
          key: i
        }, _toDisplayString(paragraph), 1))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("img", { src: _ctx.image }, null, 8, _hoisted_4)
    ])
  ]))
}