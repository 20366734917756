
import { defineComponent } from 'vue';
import Description from '@/components/util/Description.vue'

export default defineComponent({
	name: 'DatProtocol',
	components: {
		Description
	},
	data() {
		return {
			header: 'dat://protocol',
			breakpoint: 560,
			desc1: {
				text: [
					'What\'s inter-ledger communication?',
					'Inter-ledger communication (aka inter-blockchain communication) is the concept of sending information and transferring digital assets between different layer one distributed ledgers.'
				],
				image: '',
				position: 'left'
			},
			desc2: {
				text: [
					'Combined, dat.cash reimagines self-custody for a multi-distributed ledger future:'
				],
				image: '',
				position: 'left'
			}
		}
	},
	methods: {
		onResize() {
			if(window.outerWidth > this.breakpoint) {
				this.header = 'dat://protocol'
			}
			else if(window.innerWidth <= this.breakpoint) {
				this.header = 'dat:// protocol'
			}
		}
	},
	mounted() {
		this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
            window.addEventListener('scroll', this.onResize);
        })
    },
    beforeDestroy() { 
        window.removeEventListener('resize', this.onResize);
        window.addEventListener('scroll', this.onResize);
    }
});
