
import { defineComponent } from 'vue';
import Description from '@/components/util/Description.vue'

export default defineComponent({
	name: 'ObjectPermanence',
	components: {
		Description
	},
	data() {
		return {
			desc1: {
				text: [
					'Digital assets on centralized platforms exist temporarily. When a game is turned off or if a new version comes out, in-game assets are non-transferable and lost. A natural benefit of digital assets recorded on distributed ledgers is the manifestation of object permanence.'
				],
				image: '',
				position: 'left'
			},
			desc2: {
				text: [
					'However, this is only true if users also possess the private keys to their data.'
				],
				image: '',
				position: 'left'
			}
		}
	}
})
