
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'NavContent',
	data() {
		return {
			content: [
                {'path': '/', 'text': 'home'},
				{'path': '#digital-ownership', 'text': 'digital ownership'},
				{'path': '#object-permanence', 'text': 'object permanence'},
				{'path': '#end-to-end', 'text': 'end-to-end custody'}
			]
		}
	}
})
