
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'NavContent',
	data() {
		return {
			content: [
				// {'path': '#digital-ownership', 'text': 'digital ownership'},
				// {'path': '#object-permanence', 'text': 'object permanence'},
				// {'path': '#end-to-end', 'text': 'end-to-end custody'},
				{'path': '/about', 'text': 'about'},
				{'path': '#dat-cash', 'text': 'dat.cash interface'},
				{'path': '#qr-seed', 'text': 'dat://seed phrase'},
				{'path': '#dat-protocol', 'text': 'dat://protocol'}
			]
		}
	}
})
