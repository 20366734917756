import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-62a45abf"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "background" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AboutNav = _resolveComponent("AboutNav")!
  const _component_CenterAbout = _resolveComponent("CenterAbout")!
  const _component_DigitalOwnership = _resolveComponent("DigitalOwnership")!
  const _component_ObjectPermanence = _resolveComponent("ObjectPermanence")!
  const _component_EndToEndCustody = _resolveComponent("EndToEndCustody")!
  const _component_Bottom = _resolveComponent("Bottom")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_AboutNav),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_CenterAbout),
      _createVNode(_component_DigitalOwnership),
      _createVNode(_component_ObjectPermanence),
      _createVNode(_component_EndToEndCustody),
      _createVNode(_component_Bottom)
    ])
  ], 64))
}