
import { defineComponent } from 'vue';
import Description from '@/components/util/Description.vue'

export default defineComponent({
	name: 'EndToEndCustody',
	components: {
		Description
	},
	data() {
		return {
			desc1: {
				text: [
					'Today NFTs v1.0 are composed of two pieces - a native token with a URL to the data, and the data itself.',
					'In most cases, users only own the native token, but not the data - the quintessential component that makes an NFT.',
				],
				image: '',
				position: 'right'
			},
			desc2: {
				text: [
					'We introduce the concept of End-to-end custody (e2ec) - possession and transfer of ownership of all parts of a digital asset.',
					'Full digital asset ownership is cryptographically bound during transfer by dat protocol.'
				],
				image: '',
				position: 'right'
			}
		}
	}
});
