import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-581cb6de"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "navcontent" }
const _hoisted_2 = { id: "content-menu" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("nav", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.content, (item, i) => {
        return (_openBlock(), _createBlock(_component_router_link, {
          class: "router-link",
          key: i,
          to: item.path
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(item.text), 1)
          ]),
          _: 2
        }, 1032, ["to"]))
      }), 128))
    ])
  ]))
}