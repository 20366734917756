import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5398efad"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "home" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavContent = _resolveComponent("NavContent")!
  const _component_CenterLanding = _resolveComponent("CenterLanding")!
  const _component_DatCash = _resolveComponent("DatCash")!
  const _component_QRSeed = _resolveComponent("QRSeed")!
  const _component_DatProtocol = _resolveComponent("DatProtocol")!
  const _component_Bottom = _resolveComponent("Bottom")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_NavContent),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_CenterLanding),
      _createVNode(_component_DatCash),
      _createVNode(_component_QRSeed),
      _createVNode(_component_DatProtocol),
      _createVNode(_component_Bottom)
    ])
  ], 64))
}