
import { defineComponent } from 'vue';

import NavContent from '@/components/NavContent.vue';
import CenterLanding from '@/components/home/CenterLanding.vue';
import DatCash from '@/components/datcash/DatCash.vue';
import QRSeed from '@/components/datcash/QRSeed.vue';
import DatProtocol from '@/components/datcash/DatProtocol.vue';
import Bottom from '@/components/Bottom.vue';

export default defineComponent({
  name: 'HomeView',
  components: {
    NavContent,
    CenterLanding,
    DatCash,
    QRSeed,
    DatProtocol,
    Bottom
  },
});
