
import { defineComponent } from 'vue';
import Description from '@/components/util/Description.vue'

export default defineComponent({
	name: 'qr seed',
	components: {
		Description
	},
	data() {
		return {
			text: [
				'Extending E2E Custody, we simplify seed phrase management as a service with secure multi-party computation techniques to make storage and recovery robust, reliable and safe.',
			],
			image: '',
			position: 'right',
		}
	}
})
