import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '@/views/Home.vue'
import About from '@/views/About.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/about',
    name: 'about',
    component: About
  },
  {
    path: '/#digital-ownership',
    name: 'digital-ownership',
    component: Home
  },
  {
    path: '/#object-permanence',
    name: 'object-permanence',
    component: Home
  },
  {
    path: '/#end-to-end',
    name: 'end-to-end',
    component: Home
  },
  {
    path: '/#dat-cash',
    name: 'dat-cash',
    component: Home
  },
  {
    path: '/#qr-seed',
    name: 'qr-seed',
    component: Home
  },
  {
    path: '/#dat-protocol',
    name: 'dat-protocol',
    component: Home
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if(to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
        top: 214.72
      }
    }
  }
})

export default router
