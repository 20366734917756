
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'NavBar',
    components: {
    },
    data() {
        return {
            hasBackground: true
        }
    },
    methods: {
        updateHeight(event: Event): void {
            this.hasBackground = window.outerWidth <= 860;
        }
    },
    mounted() {
        window.addEventListener('scroll', this.updateHeight);
    }
});
