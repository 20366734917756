import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-65b32bdc"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "bottom",
  class: "main-content"
}
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "info" }
const _hoisted_4 = ["href"]
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { id: "copyright" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("ul", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.socials, (link, i) => {
            return (_openBlock(), _createElementBlock("li", {
              class: "links",
              key: i
            }, [
              _createElementVNode("a", {
                class: "social",
                target: "_blank",
                href: link.url
              }, _toDisplayString(link.text), 9, _hoisted_4)
            ]))
          }), 128))
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("span", _hoisted_6, "© dat.cash " + _toDisplayString(_ctx.year), 1)
    ])
  ]))
}