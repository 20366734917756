
import { defineComponent } from 'vue';
import AboutNav from '@/components/about/AboutNav.vue';
import CenterAbout from '@/components/about/CenterAbout.vue';
import DigitalOwnership from '@/components/background/DigitalOwnership.vue';
import ObjectPermanence from '@/components/background/ObjectPermanence.vue';
import EndToEndCustody from '@/components/background/EndToEndCustody.vue';
import Bottom from '@/components/Bottom.vue';

export default defineComponent({
	name: 'Background',
	components: {
		AboutNav,
		CenterAbout,
		DigitalOwnership,
		ObjectPermanence,
		EndToEndCustody,
		Bottom
	}
})
