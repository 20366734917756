
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'Description',
    // prop for text, image and boolean indicating positioned left or right to each other
    props: {
        text: Array,
        image: String,
        position: String,
    },
    data() {
        return {
        }
    }
})
