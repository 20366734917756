
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'CenterLanding',
    data() {
        return {
            header_row1: 'end-to-end',
            header_row2: 'custody',
            description: 'Take back control. Enabling secure and reliable key management as a service for a decentralized future.'
        }
    }
});
