
import { defineComponent } from 'vue';
import Description from '@/components/util/Description.vue'

export default defineComponent({
	name: 'DigitalOwnership',
	components: {
		Description
	},
	data() {
		return {
			desc1: {
				text: [
					'Users must be guaranteed provable authenticity, possession, and control of digital assets to realize the promise of a decentralized, fair and open internet.',
					'For this to be true, users must uniquely control how and where their data is stored.',
				],
				image: '',
				position: 'right'
			},
			desc2: {
				text: [
					'Currently, data for NFTs are stored on centralized solutions such as Amazon Web Services or Google Cloud Platform, or Web 3 storage systems controlled by third parties.'
				],
				image: '',
				position: 'right'
			}
		}
	}
})
