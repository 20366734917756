
import { defineComponent } from 'vue';
import Description from '@/components/util/Description.vue'

export default defineComponent({
	name: 'dat.cash',
	components: {
		Description
	},
	data() {
		return {
			text: [
				'Forming the base upon which we build end-to-end custody, we provide an interface that abstracts away both an Arweave wallet and wallet for a L1 smart contract compatible ledger.',
				'We choose Arweave for permanent storage and smart contract capability, and Solana as our first L1 for scalability.',
			],
			image: '',
			position: 'left'
		}
	}
})
