
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'Footer',
	data() {
		return {
			copyright: '&copy',
			year: new Date().getFullYear(),
			socials: [
				{url: 'https://github.com/datcashprotocol', text:'Github'},
				{url: 'https://twitter.com/datcashprotocol', text: 'Twitter'}
			]
		}
	}
})
